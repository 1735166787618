<template>
  <v-container fluid>
    <v-card elevation="0" class="px-3">
      <v-card-title class="text-h4 grey--text text--darken-3">
        My Profile
        <v-spacer></v-spacer>
      </v-card-title>
    </v-card>

    <v-card elevation="0" class="px-3 mt-3">
      <v-card class="px-5 py-8" elevation="0" max-width="40%">
        <v-form ref="myProfileForm"
                        lazy-validation>
          <v-text-field
            outlined
            label="Name"
            v-model="profile.name"
            :rules="nameRules"
          >
          </v-text-field>
          <v-text-field outlined disabled label="Email" v-model="profile.email">
          </v-text-field>
          <v-text-field
            outlined
            disabled
            label="Role"
            v-model="profile.userRole"
          >
          </v-text-field>
          <!-- <v-sheet outlined width="100%">
            <span class="caption px-2 grey--text text--darken-2"
              >Assigned Kiosks</span
            >
            <v-chip-group column class="pa-1">
              <v-chip
                small
                v-for="(kiosk, i) in profile.assignedKiosks"
                :key="i"
              >
                {{ kiosk }}
              </v-chip>
            </v-chip-group>
          </v-sheet> -->
        </v-form>

        <div class="d-flex justify-end">
          <v-btn class="my-3 mx-2" @click="showPasswordReset = !showPasswordReset " outlined color="primary" elevation="0">
            <v-icon left>mdi-lock-reset</v-icon>
            Reset Password
          </v-btn>
          <v-btn
            @click="updatedMyProfile"
            class="my-3"
            color="primary"
            elevation="0"
            >Update</v-btn
          >
        </div>
      </v-card>
    </v-card>
    <resetPassword v-if="showPasswordReset" @close="showPasswordReset = !showPasswordReset" ></resetPassword>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import resetPassword from '@/components/auth/resetPassword';

export default {
  name: "profile",
  data() {
    return {
      profile: {},
      nameRules: [(v) => !!v || "Full name is required"],
      showPasswordReset: false
    };
  },
  components:{
      resetPassword
  },
  computed: {
    ...mapGetters(["userProfile"]),
  },
  methods: {
    updatedMyProfile() {
        if(this.$refs.myProfileForm.validate()){
            this.$store.dispatch('updateRegisteredUserInformation', this.profile)
            .then(response => {
                this.$store.dispatch('showSnackBar', {
                  text: response,
                  color: 'green'
                }, error => {
                    this.$store.dispatch('showSnackBar', {
                  text: error,
                  color: 'error'
                })
                })
            })
        }
    },
  },
  mounted() {
    this.profile = { ...this.userProfile.data(), id: this.userProfile.id };
  },
};
</script>