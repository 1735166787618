import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VCard,{staticClass:"px-3",attrs:{"elevation":"0"}},[_c(VCardTitle,{staticClass:"text-h4 grey--text text--darken-3"},[_vm._v(" My Profile "),_c(VSpacer)],1)],1),_c(VCard,{staticClass:"px-3 mt-3",attrs:{"elevation":"0"}},[_c(VCard,{staticClass:"px-5 py-8",attrs:{"elevation":"0","max-width":"40%"}},[_c(VForm,{ref:"myProfileForm",attrs:{"lazy-validation":""}},[_c(VTextField,{attrs:{"outlined":"","label":"Name","rules":_vm.nameRules},model:{value:(_vm.profile.name),callback:function ($$v) {_vm.$set(_vm.profile, "name", $$v)},expression:"profile.name"}}),_c(VTextField,{attrs:{"outlined":"","disabled":"","label":"Email"},model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}}),_c(VTextField,{attrs:{"outlined":"","disabled":"","label":"Role"},model:{value:(_vm.profile.userRole),callback:function ($$v) {_vm.$set(_vm.profile, "userRole", $$v)},expression:"profile.userRole"}})],1),_c('div',{staticClass:"d-flex justify-end"},[_c(VBtn,{staticClass:"my-3 mx-2",attrs:{"outlined":"","color":"primary","elevation":"0"},on:{"click":function($event){_vm.showPasswordReset = !_vm.showPasswordReset}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-lock-reset")]),_vm._v(" Reset Password ")],1),_c(VBtn,{staticClass:"my-3",attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.updatedMyProfile}},[_vm._v("Update")])],1)],1)],1),(_vm.showPasswordReset)?_c('resetPassword',{on:{"close":function($event){_vm.showPasswordReset = !_vm.showPasswordReset}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }